var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"saas-page-intro",class:{
    'saas-page-intro--image-at-bottom':
      _vm.type === 'image_at_bottom' || _vm.type === 'image_at_bottom_scaled',
    'saas-page-intro--image-at-right': _vm.type === 'image_right',
    'saas-page-intro--background-light-to-dark':
      _vm.backgroundGradient === 'light_to_dark',
    'saas-page-intro--background-dark-to-light':
      _vm.backgroundGradient === 'dark_to_light',
  }},[(_vm.type === 'shape_default')?[_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__default-left-1"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__default-left-2"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__default-left-3"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__default-right-1"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__default-right-2"})]:_vm._e(),_vm._v(" "),(_vm.type === 'shape_vertical')?[_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-left-1"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-left-2"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-left-3"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-right-1"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-right-2"}),_vm._v(" "),_c('div',{staticClass:"saas-rounded-shape saas-rounded-shape__vertical-right-3"})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"saas-page-intro__container"},[_c('div',{staticClass:"saas-page-intro__content"},[(_vm.labelText || _vm.labelIcon)?_c('div',{staticClass:"saas-page-intro__label-wrapper"},[_c('div',{staticClass:"saas-page-intro__label"},[(_vm.labelIcon)?_c('i',{class:`saas-page-intro__label-icon ${_vm.labelIcon}`}):_vm._e(),_vm._v("\n          "+_vm._s(_vm.labelText)+"\n        ")])]):_vm._e(),_vm._v(" "),(_vm.top === 'baserow_logo')?_c('div',{staticClass:"saas-page-intro__logo-icon-v2"},[_c('img',{attrs:{"src":require("@saas/assets/images/page-intro-logo-baserow.svg"),"alt":"Baserow Logo"}})]):_vm._e(),_vm._v(" "),_c('h1',{staticClass:"saas-page-intro__title",class:{
          'saas-page-intro__title--wide': _vm.wideContent,
        },domProps:{"innerHTML":_vm._s(_vm.formattedTitle)}}),_vm._v(" "),(_vm.text)?_c('p',{staticClass:"saas-page-intro__description",class:{
          'saas-page-intro__description--wide': _vm.wideContent,
        }},[_vm._v("\n        "+_vm._s(_vm.text)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.usps.length > 0)?_c('PageIconGrid',{attrs:{"icons":_vm.usps}}):_vm._e(),_vm._v(" "),(_vm.buttons.length > 0)?_c('div',{staticClass:"saas-page-intro__actions"},_vm._l((_vm.buttons),function(button,index){return _c('nuxt-link',{key:index,class:`button button--large button--${button.type}`,attrs:{"to":button.url_or_path}},[_vm._v(_vm._s(button.text))])}),1):_vm._e()],1),_vm._v(" "),(_vm.type === 'image_right')?_c('div',{staticClass:"saas-page-intro__image-at-right"},[(_vm.image)?_c('img',{staticClass:"saas-page-intro__image-at-right-image",attrs:{"src":_vm.image.src,"alt":_vm.image.alt,"srcset":_vm.srcset}}):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.type === 'image_at_bottom' || _vm.type === 'image_at_bottom_scaled')?_c('div',{class:{
      'saas-page-intro__image-at-bottom': _vm.type === 'image_at_bottom',
      'saas-page-intro__image-at-bottom-scaled':
        _vm.type === 'image_at_bottom_scaled',
    }},[(_vm.image)?_c('img',{class:{
        'saas-page-intro__image-at-bottom-scaled-image':
          _vm.type === 'image_at_bottom_scaled',
        'saas-page-intro__image-at-bottom-image': _vm.type === 'image_at_bottom',
      },attrs:{"src":_vm.image.src,"alt":_vm.image.alt,"srcset":_vm.srcset}}):_vm._e()]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }